
import router from '@/router';
import { generalStore } from '@/store';
import { ref, onMounted } from 'vue';
import { OrderDetails, Slot } from '@/models/Order';
import swal from 'sweetalert2';
import Q from 'q';
import useOrder from '@/modules/useOrder';
import { onBeforeRouteLeave } from 'vue-router';
import CalendarKendo from '@/components/Calendar/CalendarKendo.vue';
import { useRoute } from 'vue-router';

export default {
    components: {
        CalendarKendo
    },
    async setup() {
        const { canLeave } = useOrder();
        const route = useRoute();
        onBeforeRouteLeave(async to => {
            return await canLeave(to);
        });
        let orderId = '';
        if (route.params.id) {
            orderId = route.params.id.toString();
        }
        let productId = '';
        if (route.params.id) {
            productId = route.params.productId.toString();
        }
        let customerId = '';
        if (route.params.id) {
            customerId = route.params.customerId.toString();
        }
        const loaded = ref(false);
        const order = ref(new OrderDetails());
        const slots = ref<Slot[]>([]);

        function pageDataExist() {
            if (!order.value) {
                return false;
            }
            if (!order.value.priceTypeId) {
                return false;
            }
            if (!order.value.newproduct.productId) {
                return false;
            }
            return true;
        }
        const back = () => {
            router.push({ name: 'addorderproduct' });
        };

        function onselectslot({ slotId, slot, date }: any) {
            order.value.newproduct.date = date;
            order.value.newproduct.slotId = slotId;
            order.value.newproduct.slot = slot;
            order.value.newproduct.startTime = slot.startTime;
            order.value.newproduct.duration = slot.duration;
            order.value.newproduct.customers = [];
            router.push({ name: 'addorderproductcustomertype' });
        }
        const onMountedCall = async () => {
            swal.showLoading();
            await Q.delay(400);
            order.value = generalStore.getters.getNewOrder;
            if (!pageDataExist()) {
                router.replace({ name: 'neworder' });
                swal.close();
                return;
            }
            loaded.value = true;
            swal.close();
        };
        onMounted(onMountedCall);

        swal.close();
        return {
            back,
            order,
            slots,
            onselectslot,
            loaded
        };
    }
};
